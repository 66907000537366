.c-button-title {
  position: relative;

  line-height: 1.4em;
}

  .c-button-title__text {
    opacity: 0.5;
  }

  .c-button-title__buttons {
    position: absolute !important;
    right: 11px;
    top: -10px;
  }

  .c-button-title__button {
    line-height: 28px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    min-width: 60px !important;
    margin-left: 2px !important;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: initial;
  }

  .c-button-title__button--icon {
    min-width: 30px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .c-button-title__link {
    position: absolute !important;
    right: 6px;
    top: 50%;

    line-height: 28px !important;
    padding-left: 12px !important;
    margin-top: -14px !important;
    padding-right: 12px !important;
    min-width: 60px !important;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: initial;
  }
