@import 'toolkit';

html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  background: background(main);
  color: color(mainText);
}

body {
  min-height: 100%;
  font-family: $fontMain;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}
