@use '@angular/material' as mat;
@import 'toolkit';

.c-note {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: $bu;
  font-size: 14px;
  line-height: 1.5em;
  padding: 12px;
  border-radius: 4px;

  background: mat.get-color-from-palette(mat.$orange-palette, 300);

  a {
    color: #3e3e3e;

    font-weight: 500;
  }
}
