@import 'variables';

h1, h2, h3, h4, .t-h1, .t-h2, .t-h3, .t-h4, h5, .t-h5 {
  margin: 0;
  padding: 0;
}

h3, .t-h3 {
  font-size: 22px;
  font-weight: 300;
}

h4, .t-h4 {
  font-size: 18px;
  font-weight: 300;
}

h5, .t-h5 {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
}

p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5em;
}
