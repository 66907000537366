// sizes
$widths: (
  small: 400px,
  medium: 1000px,
  big: 1340px
);

$bu: 24px;

// stacking
$zIndexes: (
  search: 4000,
  supportTrigger: 1000,
  appBar: 800
);

// easings
$materialEasing: cubic-bezier(.62,.28,.23,.99);
