@import 'toolkit';

.mat-tooltip.c-search-tooltip {
  position: relative;

  overflow: initial;
  padding: $bu * 0.4;

  opacity: 0.98;
  background: #fff;
  color: color(mainText);

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  box-shadow: 1px -9px 20px 0px rgba(0, 0, 0, 0.06),
    0px 12px 17px 2px rgba(0, 0, 0, 0.042),
    0px 5px 22px 4px rgba(0, 0, 0, 0.036);

  // &::before {
  //   content: "";

  //   position: absolute;
  //   top: -8px;
  //   left: 50%;

  //   margin-left: -4px;
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 0 8px 9px 8px;
  //   border-color: transparent transparent #ffffff transparent;
  // }
}
