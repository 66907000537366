/** vendor */
@use '@angular/material' as mat;
@import '~normalize.css/normalize';
@import '@angular/material/theming';

/** theme */
@include mat.core();
@import './theme';
@include mat.all-component-themes($theme);

/** util */
@import './util/text';
@import './util/color';
@import './util/position';
@import './util/resets';

/** base */
@import './base/page';
@import './base/typography';
@import './base/material';

/** components */
@import './components/inline-list';
@import './components/search-tooltip';
@import './components/card';
@import './components/button-title';
@import './components/snacks';
@import './components/button';
@import './components/or';
@import './components/recaptcha';
@import './components/note';
