@use '@angular/material' as mat;
@import '@angular/material/theming';

$fontMain: 'Roboto', sans-serif;

$_primary: mat.get-color-from-palette(mat.$green-palette, 500);
$_accent: mat.get-color-from-palette(mat.$blue-palette, 400);
$_warn: mat.get-color-from-palette(mat.$red-palette, 400);

$colors: (
  primary: $_primary,
  accent: $_accent,
  warn: $_warn,
  mainText: mat.get-color-from-palette(mat.$grey-palette, 800),
  identityAlpha: #3f9c35,
  identityBeta: #e3ce35,
  identityGamma: #2a5396
);

$backgrounds: (
  main: #f2f2f2
);

$themeColors: (
  primary: mat.define-palette(mat.$green-palette, 600),
  accent: mat.define-palette(mat.$blue-palette, A200, A100, A400),
  warn: mat.define-palette(mat.$red-palette)
);

$theme: mat.define-light-theme(
  map-get($themeColors, primary),
  map-get($themeColors, accent),
  map-get($themeColors, warn)
);
