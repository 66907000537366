@use '@angular/material' as mat;

.u-card--no-padding {
  padding: 0 !important;
}

.u-card--half-padding {
  padding: $bu * 0.5 !important;
}

.u-card--third-padding {
  padding: $bu * 0.333 !important;
}

.u-card--quarter-padding {
  padding: $bu * 0.25 !important;
}

.u-card--stroked {
  box-shadow: none !important;
  border: 1px solid mat.get-color-from-palette(mat.$grey-palette, 300);
}

.u-card--no-shadow {
  box-shadow: none !important;
  border: none !important;
}