.u-text--right {
  text-align: right;
}

.u-text--left {
  text-align: left;
}

.u-text--center {
  text-align: center;
}

.u-text--italic {
  font-style: italic;
}
