@import 'theme';
@import 'variables';
@import 'functions';

mat-form-field {
  width: 100%;
}

mat-card.mat-card {
  border-radius: 4px;

  &:not([class*=mat-elevation-z]) {
    border: 1px solid #e0e0e0;
    box-shadow: none;
  }
}

.mat-sort-header-sorted {
  color: color(accent);
}

zps-base-form {
  form {
    &.is-submitted {
      mat-form-field {
        &.ng-invalid:not(.mat-focused) {
          .mat-form-field-outline.mat-form-field-outline-thick {
            color: color(warn);
            opacity: 1;
          }

          .mat-form-field-invalid .mat-input-element {
            caret-color: color(warn);
          }

          .mat-form-field-label {
            color: color(warn)
          }
        }
      }
    }
  }
}

.mat-dialog-container.mat-dialog-container {
  border-radius: 16px;
}

#recordDialog,
#publishDialog,
#soaDialog {
  max-height: 90vh;
  padding: 0;
}
