@import 'variables';

@function zIndex($layer) {
  @if not map-has-key($zIndexes, $layer) {
    @warn "No layer found for `#{$layer}` in $zIndexes map.";
  }

  @return map-get($zIndexes, $layer);
}

@function background($key) {
  @if not map-has-key($backgrounds, $key) {
    @warn "No key found for `#{$key}` in $backgrounds map.";
  }

  @return map-get($backgrounds, $key);
}

@function color($key) {
  @if not map-has-key($colors, $key) {
    @warn "No key found for `#{$key}` in $colors map.";
  }

  @return map-get($colors, $key);
}

@function widths($key) {
  @if not map-has-key($widths, $key) {
    @warn "No key found for `#{$key}` in $widths map.";
  }

  @return map-get($widths, $key);
}


/* border-serrated - a zig zag triangle border with linear gradient
*/
@mixin border-top-serrated($size, $color-outer) {
  & {
      position: relative;
  overflow: auto;
      padding-top: $size;
  }
  &:before {
      top: 0px;
      background-position: left top;
      background: linear-gradient(-135deg, $color-outer $size / 2, transparent 0), linear-gradient(135deg, $color-outer $size / 2, transparent 0);
      @include border-serrated-helper($size, $color-outer);
  }
}
@mixin border-bottom-serrated($size, $color-outer) {
  & {
      position: relative;
  overflow: auto;
      padding-bottom: $size;
  }
  &:after {
      bottom: 0px;
      background-position: left bottom;
      background: linear-gradient(-45deg, $color-outer $size / 2, transparent 0), linear-gradient(45deg, $color-outer $size / 2, transparent 0);
      @include border-serrated-helper($size, $color-outer);
  }
}
@mixin border-serrated-helper($size, $color-outer) {
  content: " ";
  display: block;
  position: absolute;
  left: 0px;
  width: 100%;
  height: $size;
  background-repeat: repeat-x;
  background-size: $size $size;
}
